<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <v-checkbox
        :key="`${step.id}-isSshKey`"
        v-model="isSshKey"
        :label="$lang.labels.isSshKey"
        :readonly="!canEdit"
        dense
        class="mt-0 pt-0"
      ></v-checkbox>
    </v-col>
    <credentials-select
      :key="`${step.id}-credentialName`"
      step-type="SSH"
      :properties="properties"
      :is-ssh-key="isSshKey"
      step-label="ssh"
      :readonly="!canEdit"
      required
      @change="handleChange('credentialName', $event)"
      @updateIsSshKey="isSshKey = $event"
    />

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-command`"
        v-model="properties.command"
        outlined
        dense
        :label="$lang.labels.command"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="ssh-command"
      />
    </v-col>

    <v-col cols="9">
      <v-text-field
        :key="`${step.id}-tempTimeout`"
        v-model="tempTimeout"
        outlined
        dense
        :label="$lang.labels.changeableTimeout"
        required
        :readonly="!canEdit"
      />
    </v-col>
    <v-col cols="3">
      <v-select
        :key="`${step.id}-timeUnit`"
        v-model="timeUnit"
        :items="timeUnits"
        :label="$lang.labels.unit"
        outlined
        dense
        required
        class="pl-2"
        :readonly="!canEdit"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="ssh-targetObject"
      />
    </v-col>
  </v-row>
</template>

<script>
import { CredentialsSelect } from './components'
import StepMixin from './StepMixin'

import { TIME_UNITS, DEFAULT_TIME_UNIT } from './constants'
import { predictBestTimeUnitFromMsValue } from '@/utils/helpers'

export default {
  name: 'SSHStep',
  components: {
    CredentialsSelect
  },
  mixins: [StepMixin],
  data() {
    return {
      tempTimeout: 0,
      timeUnits: TIME_UNITS,
      timeUnit: DEFAULT_TIME_UNIT,
      isSshKey: false
    }
  },
  watch: {
    isSshKey: {
      handler(val) {
        this.$emit('changeStepProperties', { ...val })
      },
      deep: true
    },
    tempTimeout: {
      handler(val) {
        this.properties.timeout = this.$options.filters.unitFormatter(val, this.timeUnit, 'MSec')
      }
    },
    timeUnit: {
      handler(val) {
        this.properties.timeout = this.$options.filters.unitFormatter(this.tempTimeout, val, 'MSec')
      }
    }
  },
  async created() {
    if (this.properties?.timeout) {
      const unitTempTimeout = await predictBestTimeUnitFromMsValue(this.properties.timeout)

      this.timeUnit = unitTempTimeout.unit
      this.tempTimeout = unitTempTimeout.value
    }
    this.isSshKey = false
    if (!this.properties.timeout) {
      this.tempTimeout = 15
      this.properties.timeout = '15000'
    }
  }
}
</script>
